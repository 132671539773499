<template>
  <login-wrapper>
    <template v-slot:header>
      <zubut-image />
    </template>

    <template v-slot:section>
      <div v-show="!passwordChanged" class="title mb-5">
        Por favor ingresa tu nueva contraseña
      </div>
      <div v-show="passwordChanged" class="title mb-5">
        Contraseña actualizada exitosamente
      </div>
      <div v-show="!passwordChanged">
        <b-input
          id="password"
          v-model="password"
          type="password"
          class="mb-3"
          :state="passwordState"
          placeholder="Nueva contraseña"
          @keyup.enter="dispatchUpdatePassword"
        />
        <b-input
          id="newPassword"
          v-model="confirmPass"
          type="password"
          class="mb-3"
          :state="confirmPassState"
          placeholder="Confirma tu nueva contraseña"
          @keyup.enter="dispatchUpdatePassword"
        />
      </div>
      <z-button
        v-show="!passwordChanged"
        class="mb-4"
        expanded
        :disabled="!validFields || loading"
        :loading="loading"
        @click="dispatchUpdatePassword"
      >
        Actualizar contraseña
      </z-button>

      <div>
        <router-link to="/inicio-sesion">Iniciar sesión</router-link>
      </div>
    </template>

    <template v-slot:footer>
      <login-footer />
    </template>
  </login-wrapper>
</template>

<script>
import notifyMixin from "@/mixins/notify";
import LoginWrapper from "./LoginWrapper";
import ZubutImage from "./ZubutImage";
import LoginFooter from "./LoginFooter";

export default {
  name: "UpdatePassword",

  components: { LoginWrapper, ZubutImage, LoginFooter },

  mixins: [notifyMixin("login")],

  data() {
    return {
      loading: false,
      password: "",
      confirmPass: "",
      passwordChanged: false
    };
  },

  computed: {
    passwordState() {
      return this.password.length === 0
        ? null
        : this.password.length > 5
        ? true
        : false;
    },
    confirmPassState() {
      return this.confirmPass.length === 0
        ? null
        : this.confirmPass === this.password
        ? true
        : false;
    },
    validFields() {
      return this.passwordState && this.confirmPassState;
    }
  },

  methods: {
    dispatchUpdatePassword() {
      const pass = this.password;
      const route = this.$route.path.split("/");
      const secret = route[route.length - 1];
      this.loading = true;
      this.$store
        .dispatch("user/updatePasswordRecovery", { secret, pass })
        .then(() => {
          this.passwordChanged = true;
        })
        .catch(err => {
          this.$captureError(err);
          this.notify({
            title: "Error",
            text: "Hubo un error al cambiar la contraseña",
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 2rem;
  font-weight: 700;
}
</style>
